export type TextMask = (text: string) => string

export function maskDate(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");
  const length = digits.length;

  return digits.slice(0, 2) +
    (length > 2 ? '/' + digits.slice(2, 4) : '') +
    (length > 4 ? '/' + digits.slice(4, 8) : '');
}

export function maskPostalCode(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");
  const length = digits.length;

  return digits.slice(0, 5) +
    (length > 5 ? '-' + digits.slice(5, 8) : '');
}

export function maskCPF(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");
  const length = digits.length;

  return digits.slice(0, 3) +
    (length > 3 ? '.' + digits.slice(3, 6) : '') +
    (length > 6 ? '.' + digits.slice(6, 9) : '') +
    (length > 9 ? '-' + digits.slice(9, 11) : '');
}

export function maskPhoneNumber(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");
  const length = digits.length;

  if (length == 0) {
    return "";
  } else {

  return "(" + digits.slice(0, 2) +
    (
      length > 10 ?
      (") " + digits.slice(2, 3) + " " + digits.slice(3, 7) + "-" + digits.slice(7, 11)) :
      (
        (length > 2 ? ") " + digits.slice(2, 6) : "") +
        (length > 6 ? "-" + digits.slice(6, 10) : "")
      )
    );
  }
}

export function maskCardNumber(text: string, clearDefaultValue: boolean = true): string {
  const digits = text.replace(/[^0-9●]/g, "");
  const length = digits.length;

  if (digits.includes('●') && clearDefaultValue) {
    return '';
  } else {
    return digits.slice(0, 4) +
      (length > 4 ? ' ' + digits.slice(4, 8) : '') +
      (length > 8 ? ' ' + digits.slice(8, 12) : '') +
      (length > 12 ? ' ' + digits.slice(12, 16) : '');
  }
}

export function maskCardExpiration(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");
  const length = digits.length;

  return digits.slice(0, 2) +
    (length > 2 ? '/' + digits.slice(2, 4) : '');
}

export function maskCardVerificationCode(text: string): string {
  const digits = text.replace(/[^0-9]/g, "");

  return digits.slice(0, 4);
}

export function maxLengthMask(maxLength: number): TextMask {
  return (text: string): string => {
    return text.slice(0, maxLength);
  }
}